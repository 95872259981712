/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    pre: "pre",
    code: "code",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Examples"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const formatter = new Intl.NumberFormat(\"en-US\", {\n  style: \"currency\",\n  currency: \"USD\",\n  minimumFractionDigits: 2,\n})\n\nformatter.format(1000) // \"$1,000.00\"\nformatter.format(10) // \"$10.00\"\nformatter.format(123233000) // \"$123,233,000.00\"\n")), "\n", React.createElement(_components.h3, null, "References"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://flaviocopes.com/how-to-format-number-as-currency-javascript/"
  }, "How to format a number as a currency value in JavaScript")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
